.files-title {
    padding:35px;
    color: $white;
    margin-bottom: 0;

    @include below ($s) {
        padding: 20px;
    }
}

.files-link {
    display: block;
    width: 100%;
    padding: 10px 35px;
    color: $white; 
    border-top: 1px solid $white;
    display: flex;
    align-items: center;
    transition: all .2s ease-in-out; 
    &:hover {
        background-color: #8dc7d1;
        }
    img{
        margin-right: 15px;
    }

    @include below ($s) {
        padding: 10px 20px;
    }
}