.text-block {
    margin-bottom: 30px;
}

.small-image-holder {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 10px;
}

.large-image {
    margin-bottom: 40px;
    margin-top: 10px;
    width: 100%;
}

.tall-image {
    @include below ($s) {
        width: 100%;
    }
}

.quote-holder {
    margin-bottom: 40px;
    text-align: center;
    padding-top: 10px;

    img {
        height: 45px;
        margin-bottom: 20px;

        @include below ($s) {
            height: 35px;
        }
    }

    .quote-text {
        font-weight: 700;
        font-size: 33px;
        color: $darkgreen;
        line-height: 39px;
        max-width: 585px;
        margin: 0 auto;
        @include below ($s) {
            font-size: 23px;
            line-height: 29px;
        }
    }
}

.dotted {
    position: relative;
    margin-left: 16px;
    margin-bottom: 5px;
    &:before {
        content: '';
        position: absolute;
        height: 6px;
        width: 6px;
        background-color: #006E5B;
        border-radius: 50%;
        top: 50%;
        left: -15px;
        transform: translateY(-50%);
    }
}
.dotted.references {
    position: relative;
    margin-left: 16px;
    margin-bottom: 20px;
    &:before {
        content: attr(data-number);
        height:auto;
        width:auto;
        background-color:transparent;
        border-radius:0;
        font-size:20px;
        font-weight:bold;
        position: absolute;
        top: 50%;
        left: -25px;
        transform: translateY(-50%);
    }
}