.mobile-menu-nav {
    background-color: $white;
    width: 100%;
    padding: 20px;
    position: fixed;
    top: 0;
    z-index: 99999;
    display: flex;
    align-items: center;

    // &__logos {
    //     display: flex;
    //     margin-right: 30px;
    //     width: 100%;
    //     justify-content: space-evenly;
    // }

    @include above ($m) {
        display: none;
    }

    span {
        position: absolute;
        width: 100%;
        height: 2px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $black;

        &:first-of-type {
            top: 0;
            transform: none;
        }
        &:last-of-type {
            bottom: 0;
            transform: none;
            top: auto;
        }
    }
}

.hamburger-holder {
    height: 17px;
    width: 25px;
    margin-left: auto;
    position:relative;
}

.mobile-menu {
    visibility: hidden;
    position: fixed;
    width: 0%;
    right: 0;
    height: 100%;
    background-color: #A5D280;
    top: 57px;
    transition: all .2s ease-in-out;
    padding: 30px 20px 100px;
    z-index: 9999;
    a {
        color:#1C1C1C !important;
    }
    &__open {
        visibility: visible;
        width: 100%;
        overflow: auto;
        height: 100%;

        @include below ($s) {
            width: 100%;
        }

        .mobile-menu__inner {
            opacity: 1;
        }
    }

    &__inner {
        opacity: 0;
    }

    &__sub {
        max-height: 0;
        visibility: hidden;
        // transition: all .2s ease-in-out;
        transition: max-height, 0.5s linear;

        a {
            display: block;
            font-family: "league-gothic";
            font-size: 18px;
            text-transform: capitalize;
            font-weight: 400;
            padding: 10px;
        }

        &.visible {
            visibility: visible;
            max-height: 500px;
            transition: max-height, 0.5s linear;

        }
    }

    &__item {
        padding: 15px 37px 15px 0px;
        border-bottom: 1px solid #1c1c1c;
        position: relative;

        a {
            font-family: $league;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;

            @include below ($s) {
                font-size: 14px;
            }
        }
    }

}

#mobile-menu__sub-btn {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 0;
    color: #1c1c1c;
    background-color: #D4E5C2;
    text-align: center;
    line-height: 31px;
    font-size: 27px;
    top: 50%;
    transform: translateY(-50%);
}