@media print {

    .main-menu {display:none;}
    .sidebar {display:none;}
    .footer {display:none;}
    
    .site-description {display:none;}
    .site-title {display:none;}

    .article {
        overflow: visible !important;
    }

    .menu-menu-1-container {
        display: none;
    }

    .mobile-menu {
        display: none;
    }

    .mobile-menu {
        display: none !important;
    }

    .mobile-menu-nav {
        display: none;
    }

    body {
        color: #000;
        background: #fff;
        padding: 0;
        margin: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }

    .page-container {
        display: block;
        overflow: visible !important;
        display: inline;
    }

    .page-holder {
        width: 100%; 
        overflow: visible !important;
    }

    .social-share {
        display: none;
    }

    p {
        color: #000;
    }

    p span {
        color: #000;
    }


    .notes-holder {
        display: block;
    }

    .top-anchor {
        display: none;
    }

    .article__inner {
        page-break-before: always;
     }
    
    }