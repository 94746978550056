//Colors

$darkgreen: #17a4b7;
$lightgreen: #E6F4F6;
$regulargreen: #E3ECDB;
$white: #FFF;
$blue: #6DAFBA;
$grey: #F2F2F2;
$black: #262729;
$lightblue: #E9F1F0;
$orange: #ED9F87;

//Background-colors

.bg-darkgreen {
    background-color: $darkgreen;
}

.bg-white {
    background-color: $white;
}

.bg-blue {
    background-color: $blue;
}

.bg-lightgreen {
    background-color: $lightgreen;
}

.bg-green {
    background-color: $lightgreen;
}

.bg-orange {
    background-color: $orange;
}

.bg-grey {
    background-color: $grey;
}

//fonts

@font-face {
	font-family: 'Conv_ScalaSansBlack';
	src: url('../fonts/ScalaSansBlack.eot');
	src: local('☺'), url('../fonts/ScalaSansBlack.woff') format('woff'), url('../fonts/ScalaSansBlack.ttf') format('truetype'), url('../fonts/ScalaSansBlack.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}


$league: 'ff-scala-sans-pro';
$arial: 'ff-scala-sans-pro';
$scalablack: 'Conv_ScalaSansBlack';

//colors

.c-black {
    color: $black;
}

.c-white {
    color: $white;
}

//containers

.page-container {
    max-width: 1205px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding-top: 80px;

    @include below ($s) {
        padding-top: 50px;
    }
}

.page-holder {
    width: 70%;
    padding: 50px 80px 120px;
    @include below ($m) {
        width: 100%;
        max-width: 90%;
        margin: 0 auto;
        padding: 50px 50px 100px;
    }

    @include below (500px) {
        padding: 10px 10px 70px;
    }
}

//image
.image {
    width: 100%;
    margin-bottom: 20px;
}

//text

.site-title {
    font-family: $scalablack;
    font-size: 24px;
    color: $black;
    line-height: 31px;
    margin-bottom: 45px;
    margin-top: 10px;
    text-transform: uppercase;
    @include below ($s) {
        margin-bottom: 20px;
        margin-top: 0;
        font-size: 15px;
    }
}

h1 {
    font-family: $scalablack;
    font-size: 46px;
    line-height: 55px;
    color: $black;
    margin-bottom: 10px;
    text-transform: uppercase;
    @include below ($s) {
        font-size: 36px;
        line-height: 45px;
        margin-top: 10px;
    }
}

h2 {
    font-family: $scalablack;
    font-size: 38px;
    color: $black;
    line-height: 48px;
    text-transform: uppercase;
    margin-bottom: 25px;

    @include below ($s) {
        font-size: 26px;
        line-height: 35px;
    }
}

h3 {
    font-family: $scalablack;
    font-size: 26px;
    color: $black;
    line-height: 31px;
    margin-bottom: 10px;
}

h4 {
    font-family: $scalablack;
    font-size: 28px;
    color: $black;
    line-height: 31px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 700;
}

h5 {
    font-family: $scalablack;
    font-size: 36px;
    line-height: 55px;
    color: $black;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.page-title-h1 {
    font-family: $scalablack;
    font-size: 46px;
    line-height: 55px;
    color: $black;
    margin-bottom: 40px;
    text-transform: none;
    @include below ($s) {
        font-size: 36px;
        line-height: 45px;
        margin-top: 10px;
    }
}

.page-title-h2 {
    text-transform: none;
}

blockquote {
    margin: 0 0 60px 0px !important;
    @include below ($s) {
        margin: 0 0 30px 0px !important;
    }
    p {
    font-family: $arial;
    font-size: 21px;
    line-height: 33px;
    color: $black;
    font-weight: 400;

    @include below ($s) {
        font-size: 17px;
        line-height: 23px;
    }
    }
}

p {
    font-family: $arial;
    font-size: 16px;
    line-height: 30px;
    color: $black;

    @include below ($s) {
        font-size: 15px;
        line-height: 24px;
    }
}

a {
    font-family: $arial;
    font-size: 17px;
    font-weight: 700;
    text-decoration: none;
    color: $darkgreen;
}

ul {
    li {
        list-style: none;
        margin-bottom: 15px;
        font-family: $arial;
        font-size: 17px;
        color: $black;

        &:before {
            content: "• ";
            color: $darkgreen;
        }
    }
}

ol {
    padding: 0 30px;
    li {
        margin-bottom: 15px;
        font-family: $arial;
        font-size: 17px;
        line-height: 27px;
        color: $black;
    }
}

.preamble {
    font-family: $arial;
    font-size: 20px;
    line-height: 30px;
    color: $black;
    font-weight: 400;
    margin-bottom: 35px;

    @include below ($s) {
        font-size: 17px;
        line-height: 25px;
    }
}

//divider

.divider {
    border-top: 2px solid $darkgreen;
    margin-bottom: 80px;

    @include below ($s) {
        margin-bottom: 70px;
    }
}



//notes

span.note {
    vertical-align: baseline;
    position: relative;
    top: -0.6em;
    font-size: 12px;
    font-family: $arial;
}

//margin

.no-margin {
    margin: 0 !important;
}

//body

body {
    overflow-x: hidden;
    @include below ($m) {
        padding-top: 74px;
    }

    @include below (350px) {
        padding-top: 68px;
    }
}

.locked {
    overflow: hidden;
}

.numbered {
    span {
        font-weight:bold;
        font-size:20px;
    }
}