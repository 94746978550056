.main-menu {
    padding: 40px 0 40px;
	background-color:#B7DAF4;

    @include below ($m) {
       min-height: 70px;
    }
    @include below ($s) {
        min-height: 60px;
     }
    &-inner {
        max-width: 1440px;
        width: 100%;
        display: flex;
		margin: 0 auto;
		padding:0 40px;
        justify-content: space-between;
        align-items: center;

        span {
            font-family: $league;
            font-size: 50px;
            color: $white;
            text-transform: uppercase;

            @include below ($m) {
                font-size: 29px;
            }

            @include below ($s) {
                font-size: 25px;
            }
        }
    }
}

.menu-a {
    font-weight: 400;
    font-size: 0;
    width: 33%;

    @include below ($m) {
        width: 100%;
        text-align: center;
    }
}

.menu-a-spec {
    @include below ($m) {
        display: none;
    }
}

.img-jamt {
    @include below ($m) {
    width: 40%;
    }
}

.img-region {
    float: right;
    @include below ($m) {
    width: 70%;
    float: none;
    display: none;
    }
}

.menu-menu-1-container {
    background-color: #D1E6F7;
    @include below ($m) {
        display: none;
    }

}
.menu {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;

    li {
        margin-bottom: 0px;
        text-align: center;
        position: relative;
        height: 72px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        width: 100%;
        justify-content: center;

        &:after {
            content: '';
            width: 1px;
            background-color: #B6D0EA;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
        }
        a {
            color: #1C1C1C;
            font-size: 14px;
            transition: all .2s ease-in-out;
            text-overflow: ellipsis;
            overflow: hidden;
			font-weight:400;

            &:hover {
                font-weight:700;
            }

            span {
                font-weight: 400;
            }
        }
        &:before {
            display: none;

        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }
}

.current-menu-item {
	background-color:#B7DAF4;
    a {
        color: #1C1C1C !important;
		position: relative;
		font-weight:700 !important;
    }
}

.main-logo {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    text-align: center;
    img {
        max-width:100%;
        height:auto !important;
    }
    @include below ($m) {
        width: 60%;
        height: auto !important;
    }
    @include below ($s) {
        width: 80% !important;
        height: auto !important;
	}

	h2 {
		font-size:55px;
		line-height:35px;
		margin:0;
		color:#fff;
	}

	span {
		font-size:24px;
		line-height:35px;
	}
}