.facts-box {
    padding: 30px 20px;
    margin: 60px 0;
    a {
        display: inline;
    }
    p {
        display: inline;
    }
}

.social-share {
    margin-top: 45px;
    margin-bottom: 100px;
    position: relative;

    @include below ($s) {
        margin-bottom: 60px;
    }

    span {
        font-family: $league;
        font-size: 26px;
        color: #C6D8B7;
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 80px;
        font-weight: 700;
    }
}

.share-icon {
    background-color: #D4E5C2;
    position: relative;
    height: 55px;
    width: 65px;
    display: inline-block;
    transition: all .2s ease-in-out;

    &:hover {
        background-color: #0e8696;
    }

    .facebook-logo {
        background-image: url(../img/facebook_share.svg);
        position:absolute;
        width: 32px;
        height: 32px;
        background-size: 32px 32px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.social-fixer {
    margin-top: 45px;
    margin-bottom: 100px;
}

.notes {
    padding: 50px;
    position: relative;
    @include below ($s) {
        padding: 20px;
    }
    p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 5px;
    }

    &-holder {
        display: none;

        p {
            color: $white;
        }
    }
}

.notes-btn {
    position: absolute;
    width: 60px;
    height: 60px;
    background-image: url(../img/top-anchor.svg);
    background-position: center center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    top: 50px;
    right: 50px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &.toggled {
        transform: rotate(0deg);
    }

    @include below ($s) {
        top: 15px;
        right: 5px;
    }
}