.footer {
	background-image:url('../img/footer-bg.png');
	background-position:center top;
	background-size:100% 180px;
	background-color:#E6F4F6;
	background-repeat:no-repeat;
	height:180px;
	padding:60px 0 20px;

	&__inner {
		max-width:1205px;
		width:100%;
		margin:0 auto;
		display:flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__column {
		align-self:center;
	}
}

.footer-download-link {
	background-image:url('../img/download_symbol.svg');
	background-size:39px 45px;
	background-repeat:no-repeat;
	padding-left:58px;
	height:45px;
	line-height:45px;
	display: block;
}